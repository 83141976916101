<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form :inline="true" label-position="right" :model="formInline" class="demo-form-inline">
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.Audit_status')">
                <el-select
                  v-model.trim="formInline.authStatus"
                  @change="auth"
                  style="width: 150px"
                  placeholder=""
                >
                  <el-option label="请选择认证状态" value=""></el-option>
                  <el-option label="待审核" value="0"></el-option>
                  <el-option label="已审核" value="1"></el-option>
                  <el-option label="已作废" value="2"></el-option>
                </el-select>
              </el-form-item>
            </div>
          </div>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table
          header-cell-class-name="header-call-style"
          border-collapse:
          collapse
          v-loading="loading"
          :data="tableData"
          style="width: 100%"
          @sort-change="sortChange"
        >
          <el-table-column type="index" :label="$t('list.index')" width="70" align="center"></el-table-column>
          <!-- <el-table-column type="selection" width="55"></el-table-column> -->
          <el-table-column :label="$t('searchModule.Audit_status')" width="" align="center">
            <template slot-scope="scope">
              <p v-if="scope.row.authStatus == 1">
                <span
                  style="
                    width: 5px;
                    height: 5px;
                    border-radius: 50%;
                    background: red;
                    display: inline-block;
                  "
                ></span>
                <span>已审核</span>
              </p>
              <p v-if="scope.row.authStatus == 0">
                <span
                  style="
                    width: 5px;
                    height: 5px;
                    border-radius: 50%;
                    background: #108de9;
                    display: inline-block;
                  "
                ></span>
                <span>待审核</span>
              </p>
              <p v-if="scope.row.authStatus == 2">
                <span
                  style="
                    width: 5px;
                    height: 5px;
                    border-radius: 50%;
                    background: #989898;
                    display: inline-block;
                  "
                ></span>
                <span>已作废</span>
              </p>
            </template>
          </el-table-column>
          <el-table-column
            :prop="item.prop"
            border
            :label="item.label"
            :width="item.width"
            :sortable="item.sortable"
            v-for="item in tableCols"
            :key="item.label"
            :formatter="item.formatter"
            align="center"
          ></el-table-column>
          <el-table-column
            label="操作"
            align="center"
            width="100"
            v-if="$route.meta.authority.button.detail"
          >
            <!-- v-if='$route.meta.authority.button.view || $route.meta.authority.button.edit || $route.meta.authority.button.configure'   -->
            <template slot-scope="scope">
              <AuthorityComponent
                ComponentName="el-button"
                :permission="['subPage.detail.button.auth']"
                v-if="scope.row.authStatus == 0"
                type="text"
                style="width: 70px; margin: 0"
                @click="audit(scope.row)"
                >审核</AuthorityComponent
              >
              <AuthorityComponent
                ComponentName="el-button"
                :permission="['subPage.detail.button.query']"
                v-if="scope.row.authStatus == 1 || scope.row.authStatus == 2"
                type="text"
                style="width: 70px; margin: 0"
                @click="detail(scope.row)"
                >查看</AuthorityComponent
              >
            </template>
          </el-table-column>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper">
          <div class="block">
            <el-pagination
              v-if="total > 0"
              @current-change="handleCurrentChange"
              :current-page="page"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { exportExcelNew } from "@/common/js/public";
export default {
  name: "memberCarAuth",
  components: {},
  data() {
    return {
      index: 0,
      total: 0,
      pageSize: 15,
      page: 1,
      loading: false,
      parkList: [],
      tableCols: [
        {
          prop: "plateNumber",
          label: "提交信息",
          width: "",
        },
        {
          prop: "operation",
          label: this.$t("list.Operator"),
          width: "",
        },
        {
          prop: "applyTime",
          label: "提交日期",
          width: "200",
        },
        {
          prop: "authTime",
          label: "更新日期",
          width: "",
          // formatter: function(row, column, cellValue, index) {
          //   switch (cellValue) {
          //     case 1:
          //       return '在职'
          //     case 2:
          //       return '离职'
          //     default: '-'
          //       break;
          //   }
          // }
        },
      ],
      tableData: [],
      formInline: {
        authStatus: "",
      },
    };
  },
  methods: {
    // 条件搜索
    auth() {
      this.page = 1;
      this.searchData();
    },
    sortChange(column, prop, order) {
      if (column.prop === "createTime") {
        this.formInline.orderBy = 0;
        this.formInline.direction = column.order === "ascending" ? "0" : "1";
        this.searchData();
      }
    },
    // 审核
    audit(cmd, data) {
      this.$router.push({ path: "/DrivingcertificationDetail", query: cmd });
    },
    // 查看
    detail(cmd, data) {
      this.$router.push({ path: "/DrivingcertificationDetail", query: cmd });
    },
    // 设置离职
    // deleteGarage(data) {
    //   this.$confirm(`确定删除该条标签？`, {
    //     confirmButtonText: this.$t('pop_up.Determine'),
    //     cancelButtonText: '取消',
    //     type: 'warning'
    //   }).then(() => {
    //     this.$axios.get(`/acb/2.0/pppManager/update/${data.pppManagerId}/${data.pppManagerState}`)
    //   .then(res => {
    //     this.loading = false;
    //     if (res.state == 0) {
    //       this.$message({
    //         type: 'info',
    //         message: '删除成功！'
    //       });
    //       this.searchData()
    //     }
    //   })
    //   }).catch(() => {
    //     this.$message({
    //       type: 'info',
    //       message: '已取消'
    //     });
    //   })
    // },
    // 分页处理
    handleCurrentChange(val) {
      this.page = val;
      this.searchData();
    },
    // 搜索
    searchData() {
      this.loading = true;
      let url = "/acb/2.0/memberCarAuth/query	";
      this.$axios
        .post(url, {
          data: {
            authStatus: this.formInline.authStatus,
            page: this.page,
            pageSize: this.pageSize,
          },
        })
        .then((res) => {
          this.loading = false;
          this.total = res.value.total * 1;
          this.tableData = res.value.list;
        });
    },
  },
  beforeDestroy() {},
  activated() {
    this.searchData();
  },
  created() {
    // this.searchData();
  },
  computed: {},
  mounted() {
    if (this.$route.meta.authority) {
      let data = {};
      if (sessionStorage.getItem("authority")) {
        data = JSON.parse(sessionStorage.getItem("authority"));
      }
      data["parkList"] = this.$route.meta.authority;
      sessionStorage.setItem("authority", JSON.stringify(data));
    }
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.detail
  width: 61.4%;
  height: 82.9%;
  position: absolute;
  top: 8.6%;
  left: 20.1%;
  background: #FFFFFF;
  box-shadow: 0 0 4px 1px rgba(128,145,165,0.30);
  border-radius: 3px;
  z-index: 1000;
  box-sizing: border-box;
  padding: 20px 0;
  .title
    position: absolute;
    background: #3F4A56;
    border-radius: 3px 3px 0 0;
    color: #fff;
    width: 100%;
    top: 0;
    left: 0;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    padding: 0 12px;
    box-sizing: inherit;
    z-index: 100;
    .el-icon-close
      float: right;
      margin-top: 14px;
  .info
    margin-top: 20px;
    padding: 2.1%;
    height: 100%;
    overflow: auto;
    box-sizing: inherit;
.mask
  background: rgba(49,53,65,0.60);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
.el-dropdown-link
 cursor pointer
</style>
